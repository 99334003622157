import { action, observable } from "mobx";
import { WhichChatAsset } from "~/hooks/useWhichChat";
import type { WhichAIResult } from "~/types";
import { client } from "~/utils/ApiClient";
import {
  APIResponse,
  WhichAIAssistantQueryProps,
  WhichAICollectionQueryProps,
  WhichAICopilotQuery,
  WhichAICreateDocumentProps,
  WhichAIDeleteChat,
  WhichAIQueryProps,
  WhichAIUpdateChat,
  WhichConversation,
  WhichConversationResponse,
} from "./types";

export default class WhichAIStore {
  @observable
  conversations: WhichConversation[] = [];

  @action
  query = async (
    payload: WhichAIQueryProps,
    onReceiveData: (chunk: string) => void
  ) => {
    const { query, documentId, intentions, assetsIds } = payload;
    const response = await fetch("/api/whichai.document.query", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query,
        documentId,
        intentions,
        assetsIds,
      }),
    });

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error("failed to get reader");
    }
    const decoder = new TextDecoder();
    let finished: boolean = false;

    while (!finished) {
      const { done, value } = await reader.read();
      if (done) {
        finished = true;
        break;
      }
      if (value) {
        const chunk = decoder.decode(value, { stream: true });
        onReceiveData(chunk);
      }
    }
  };

  @action
  assistantQuery = async (
    payload: WhichAIAssistantQueryProps,
    onReceiveData: (chunk: string) => Promise<void>
  ) => {
    const { query, intentions, assetsIds, conversationId } = payload;
    const response = await fetch("/api/whichai.query", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query,
        intentions,
        assetsIds,
        conversationId,
      }),
    });

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error("failed to get reader");
    }
    const decoder = new TextDecoder();
    let finished: boolean = false;

    while (!finished) {
      const { done, value } = await reader.read();
      if (done) {
        finished = true;
        break;
      }
      if (value) {
        const chunk = decoder.decode(value, { stream: true });
        await onReceiveData(chunk);
      }
    }
  };

  @action
  collectionQuery = async (
    payload: WhichAICollectionQueryProps
  ): Promise<WhichAIResult> => {
    const { query, collectionId } = payload;
    const res = await client.post("/whichai.collection.query", {
      query,
      collectionId,
    });

    return res;
  };

  @action
  createDocumentWithAi = async (
    payload: WhichAICreateDocumentProps
  ): Promise<{
    ok: boolean;
    response?: { data: { id: string } | null };
    message: string | null;
  }> => {
    const { documentIds, docProps, prompt } = payload;
    const res = await client.post(
      "/whichai.create",
      {
        documentIds,
        docProps,
        prompt,
      },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  copilotQuery = async (
    payload: WhichAICopilotQuery
  ): Promise<{
    ok: boolean;
    response?: {
      suggestion: string;
    };
    status: number;
  }> => {
    const { cursor, documentId, collectionId } = payload;
    const res = await client.post(
      "/whichai.copilot.query",
      { cursor, documentId, collectionId },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  deleteChat = async (
    payload: WhichAIDeleteChat
  ): Promise<{
    ok: boolean;
    response?: string;
    status: number;
  }> => {
    const { documentId } = payload;
    const res = await client.post(
      "/whichai.delete.chat",
      { documentId },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  resumeCollection = async (
    collectionId: string
  ): Promise<{
    ok: boolean;
    response?: string;
    status: number;
  }> => {
    const res = await client.post(
      "/whichai.collection.resume",
      { collectionId },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  updateChat = async (
    payload: WhichAIUpdateChat
  ): Promise<{
    ok: boolean;
    response?: string;
    status: number;
  }> => {
    const { id, hasFeedback, message } = payload;
    const res = await client.put(
      "/which.message",
      { id, hasFeedback, message },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  createConversation = async (payload: {
    id?: string;
    message: string;
  }): Promise<APIResponse<WhichConversationResponse>> => {
    const { id, message } = payload;
    const res = await client.post(
      "/which.conversation",
      { id, message },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  renameConversation = async (payload: {
    id: string;
    title: string;
  }): Promise<APIResponse<WhichConversationResponse>> => {
    const { title, id } = payload;
    const res = await client.put(
      `/which.conversation/${id}`,
      { title },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  fetchConversations = async (): Promise<APIResponse<WhichConversation[]>> => {
    const res = await client.get("/which.conversations", {
      retry: false,
    });
    return res;
  };

  @action
  refreshConversations = async () => {
    const response = await this.fetchConversations();
    this.conversations = response.data;
  };

  @action
  getConversation = async (id: string): Promise<WhichConversation | null> => {
    const res = await client.get(`/which.conversation/${id}`, {
      retry: false,
    });
    return res.data;
  };

  @action
  refreshConversation = async (id: string) => {
    const conversation = await this.getConversation(id);
    if (conversation) {
      this.conversations = this.conversations.map((cv) =>
        cv.id === id ? conversation : cv
      );
    }
  };

  @action
  deleteConversation = async (id: string) => {
    const res = await client.fetch(
      `/which.conversation/${id}`,
      "DELETE",
      undefined,
      {
        retry: false,
      }
    );
    return res.data;
  };

  @action
  createWhichChatAsset = async (
    messageId: string,
    file: File
  ): Promise<WhichChatAsset> => {
    const form = new FormData();
    form.append("file", file);
    form.append("messageId", messageId);
    const res = await client.fetch("/whichChatAssets.create", "POST", form, {
      retry: false,
    });
    return res.data;
  };

  @action
  getWhichChatAsset = async (
    assetId: string
  ): Promise<WhichChatAsset & { url: string }> => {
    const res = await client.fetch(
      "/whichChatAsset",
      "POST",
      { assetId },
      {
        retry: false,
      }
    );
    return res.data;
  };
}
